.webhook {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em;
  margin: 2em 1em;
  background-color: #075191;
  border-radius: 25px;
}

.webhook h3 {
  color: #fff;
  font-size: 30px;
  margin-bottom: 1em;
}

.webhook p {
  color: #fff;
  text-decoration: none;
  font-size: 20px;
  cursor: pointer;
}

.webhook p:hover {
  text-decoration: underline;
}
