.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  box-sizing: border-box;
  padding: 20px;
}

.phone-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
}

.phone-form label {
  margin-bottom: 10px; /* reduce the bottom margin */
  display: block;
  font-weight: bold;
}

.phone-form label small {
  font-style: italic;
}

.form-input {
  margin: 0;
  padding: 10px;
}

.error-message {
  color: red;
}

.submit-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #0056b3;
}
