/* billing.css */
.form-container {
  max-width: 500px;
  max-height: 500px;
  margin: 0 auto;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.wallet-balance {
  display: flex;
  max-width: fit-content;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #e0f7fa;
  border-radius: 4px;
  font-size: 18px;
  color: #00796b;
}

.wallet-balance p {
  margin: 0;
  font-size: 24px;
}

.wallet-balance span {
  font-weight: bold;
  font-size: 24px;
}

.form-container form {
  display: flex;
  flex-direction: column;
}

.form-container form div {
  margin-bottom: 15px;
}

.form-container form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.form-container form input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.form-container form button {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.form-container form button:hover {
  background-color: #0056b3;
}

.current-card {
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 4px;
  max-width: fit-content;
  margin: 2em auto;
}

.current-card h2 {
  margin-top: 0;
}
