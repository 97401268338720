.App {
  position: relative;
}

.side-panel {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 200px; /* adjust as needed */
  background: #ffffff;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.3);
  overflow-y: auto;
  z-index: 1000; /* make sure the panel is on top */
  transition: transform 0.3s ease-in-out;
  transform: translateX(0);
}

.side-panel ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.side-panel ul li {
  padding: 10px 0;
  /* border-bottom: 1px solid #ddd; */
}

.panel-link {
  text-decoration: none;
  color: #333;
  display: block;
}

.panel-link li {
  padding: 10px 0;
  background: #ddd;
  margin-bottom: 1em;
  border-radius: 10px;
}

.panel-link li span {
  margin-left: 10px;
  font-weight: bold;
}

.panel-link:hover {
  text-decoration: underline;
}

.side-panel ul li:last-child {
  border-bottom: none;
}

.side-panel ul li:hover {
  cursor: pointer;
}

.side-panel button {
  background: #333;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin-bottom: 20px;
  cursor: pointer;
}

.toggle-panel-btn {
  position: fixed;
  top: 20px;
  left: 20px;
  background: #333;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.side-panel button:hover {
  background: #444;
}

.content {
  margin-left: 250px; /** same as the panel width */
}
